import React from 'react';
import { TextInput } from 'react-native-paper';


export default function Input(props) {

    return (

        <TextInput {...props}
            left={<TextInput.Icon name={props.icon} color="black"/>}
            style={{
            backgroundColor: 'white',
            marginLeft: 20,
            marginRight: 20,
            }}
            mode="outlined"
            outlineColor="white"
            selectionColor="white"
            activeOutlineColor="black"
            theme={{
                colors: {
                    placeholder:"lightgray",
                    text:"black",
                }
            }}
        />

    );
};