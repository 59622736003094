import React, { useState, useEffect } from 'react';
import { View, Text, ImageBackground } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';

import { background } from './assets/index.js';

import Bulle from './components/Bulle';
import Input from './components/Input';
import Bouton from './components/Bouton';

function Home() {
  return (
    <ImageBackground source={background} style={{flex:1}}>
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

    </View>
    </ImageBackground>
  );
}

function Notifications() {

  const [code, setCode] = useState();
  const [titre, setTitre] = useState();
  const [description, setDescription] = useState();
  const [lien, setLien] = useState();
  const [image, setImage] = useState();

  function ajouter(){
    const url = "http://185.232.71.196/" + code + "/releases";
    const data = {
        Titre: titre,
        Description: description,
        Lien: lien,
        Image: image
    }
    console.log(url);
    
    fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=utf-8'},
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(data => {console.log(data); alert ("C'est fait !");})
    .catch(error => {console.log(error); alert ("Oups, c'est cassé...");});
  }
    
  

  return (
    <ImageBackground source={background} style={{flex:1}}>
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Bulle
        icon = "application-import"
        title = "Ajouter une notification"
        content = {(
          <View>
            <Input
                label="Code"
                value={code}
                onChangeText={setCode}
                icon="barcode"
            />
            <Input
                label="Titre"
                value={titre}
                onChangeText={setTitre}
                icon="text-shadow"
            />
            <Input
                label="Description"
                value={description}
                onChangeText={setDescription}
                icon="tag" 
            />
            <Input
                label="Lien"
                value={lien}
                onChangeText={setLien}
                icon="link"
            />
            <Input
                label="Lien vers image"
                value={image}
                onChangeText={setImage}
                icon="camera"
            />
            <Bouton
              label="Ajouter"
              action={ajouter}
            />
          </View>
        )}
      />

    </View>
    </ImageBackground>
  );
}

function CustomDrawerContent({navigation}) {
  return (
    <DrawerContentScrollView>
      <DrawerItem
        labelStyle={{color: "white", fontSize: 20}}
        label="Home"
        icon={() => <IconButton color="white" icon="home"/>}
        onPress={() => navigation.navigate('Home')}
      />
      <DrawerItem
        labelStyle={{color: "white", fontSize: 20}}
        label="Notifications"
        icon={() => <IconButton color="white" icon="bell"/>}
        onPress={() => navigation.navigate('Notifications')}
      />
    </DrawerContentScrollView>
  );
}

const Drawer = createDrawerNavigator();

function MyDrawer() {
  return (
    <Drawer.Navigator
      initialRouteName="Home"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        headerTintColor: 'white',
        headerStyle: { backgroundColor: '#0f1020'},
        drawerStyle: {
          backgroundColor: '#190e20',

        },
        drawerItemStyle:{color: "white"}
      }}
    >
      <Drawer.Screen name="Home" component={Home} />
      <Drawer.Screen name="Notifications" component={Notifications} />
    </Drawer.Navigator>
  );
}

export default function App() {

  return (
    <NavigationContainer>
      <MyDrawer />
    </NavigationContainer>
  );
}