import React from 'react';
import { View } from 'react-native';
import { Card, Avatar } from 'react-native-paper';


export default function Bulle(props) {

    return (

        <View style={{marginTop: 20, width: 340, borderRadius: 12, backgroundColor: 'transparent'}}>


            <Card.Title
                style={{backgroundColor: 'white', marginTop: 25, borderTopLeftRadius: 12, borderTopRightRadius: 12}}
                titleStyle={{color: 'black', marginLeft: 20, marginTop: 40, fontWeight: 'bold'}}
                title={props.title}
                subtitleStyle={{color: 'gray', marginLeft: 20}}
                subtitle={props.subtitle}
                left={props.left}
            />
            <View style={{backgroundColor: 'white', color:'black', borderBottomLeftRadius: 12, borderBottomRightRadius: 12}}>
                {props.content}
            </View>
                
            <View style={{
                position: 'absolute',
                marginLeft: 220,
                marginTop: 0,
                backgroundColor: '#bf82f1',
                width: 50,
                height: 50,
                borderRadius: 12,
                alignContent: 'center',
                justifyContent: 'center'
            }}>
                <Avatar.Icon icon={props.icon} color="white" style={{backgroundColor: '#bf82f1'}} size={50} />
            </View>

        </View>

    );
};