import React from 'react';
import { Button } from 'react-native-paper';


export default function Bouton(props) {

    return (

        <Button
            style={{
                backgroundColor: "transparent",
                borderRadius: 25,
                margin: 30,
                width: 280,
                height: 56,
                borderColor: "black",
                justifyContent: "center",
            }}
            color="black"
            mode='outlined'
            onPress={props.action}
        >{props.label}</Button>

    );
};


